'use client'

import Image from 'next/image'
import React, { useEffect, useRef } from 'react'
import { ModalType } from '@enums/ModalType'
import { OVERLAY_MODAL_DEFAULT_CROSS_ICON_SRC } from '@constants/crossIcon'
import styles from './overlayModal.module.scss'

const OverlayModal = ({
  children,
  show,
  setShow,
  modalType = ModalType.MEDIUM,
  crossIconSrc = OVERLAY_MODAL_DEFAULT_CROSS_ICON_SRC,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null)

  const closeModal = event => {
    if (dropdownRef?.current !== null) {
      const isClickInside = dropdownRef?.current.contains(event.target)
      if (!isClickInside) {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }

  useEffect(() => {
    show ? (document.documentElement.style.overflowY = 'hidden') : (document.documentElement.style.overflowY = 'scroll')
  }, [show])

  return show ? (
    <div
      className={`${styles.overlayRootContainer} ${styles[modalType]}`}
      onClick={event => {
        closeModal(event)
      }}>
      <div className={`${styles.modalContainer} ${show ? styles.active : ''}`} ref={dropdownRef}>
        <Image
          src={crossIconSrc}
          alt="Overlay cross Icon"
          width={24}
          height={24}
          loading="lazy"
          onClick={() => {
            setShow(false)
          }}
          className={styles.crossIcon}
        />
        {children}
      </div>
    </div>
  ) : null
}

export default OverlayModal
